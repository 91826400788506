import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { IntlContext } from './IntlWrapperProvider';
import React from 'react';
import { FormattedMessage } from "react-intl";
import $ from 'jquery'

const Header = ({ availableLocales, hotelLogo, hotelName }) => {
    const { switchToLangPT, switchToLangEN, switchToLangFR, switchToLangES, switchToLangDE, switchToLangPL, switchToLangIT, locale, params } = React.useContext(IntlContext);

    function FixRoot(){
      if($("#root").css('position') === 'fixed'){
        $("#root").css({"position": "relative"});
      }
    }

    const switchToLang = (lang) => {
      if(lang === "de-DE" || lang === "de"){
        switchToLangDE();
        FixRoot();
        return;
      };
      
      if(lang === "fr-FR" || lang === "fr"){
        switchToLangFR();
        FixRoot();
        return;
      };
      
      if(lang === "es-ES" || lang === "es"){
        switchToLangES();
        FixRoot();
        return;
      };
      
      if(lang === "pt-PT" || lang === "pt"){
        switchToLangPT();
        FixRoot();
        return;
      };
      
      if(lang === "pl-PL" || lang === "pl"){
        switchToLangPL();
        FixRoot();
        return;
      };
      
      if(lang === "it-IT" || lang === "it"){
        switchToLangIT();
        FixRoot();
        return;
      };

      switchToLangEN();
      FixRoot();
      return;
    }
    
    return (
      <Row className='customHeader surveyHeader'>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          {hotelLogo ?
            <img src={hotelLogo} style={{ maxWidth: '70%', height: 'auto', maxHeight: '70px' }} alt="Logo" className='mr-4'/>
          :''}
            {hotelName ?
              <div className='hotelName'>
                <h4 className='m-0'>{hotelName}</h4>
              </div>
            :''}
        </Col>
        {!params && availableLocales.length > 1 ?
          <Col className="text-right col-2" style={{ display: 'flex', alignItems: 'center', padding: '0' }}>
              <UncontrolledDropdown style={{ textAlign: 'center', marginLeft: 'auto' }} className="cursor-pointer" direction="down" >
                  <DropdownToggle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} tag="a" className="dropdown-item" caret>
                      <i className="fas fa-globe mr-1" />
                      <FormattedMessage id={locale}/>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {availableLocales.map((lang, key) =>
                      <DropdownItem key={key} onClick={() => switchToLang(lang)} active={locale === lang}>
                        <FormattedMessage id={lang}/>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
              </UncontrolledDropdown>
          </Col>
        :''}
      </Row>
    );
}

export default Header;