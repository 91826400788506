import React from 'react'
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export default function ReCaptchaContainer({ handleVerify, hasToValidateCaptcha, children }) {
    // FOR DEV AND PRD "6Lcom1gpAAAAAEWARc1vjBY8b22oFbrT5B1mGyB1"
    // for testing: 6LeZD1kpAAAAAGfKUCGWF12x3T94AptFM4gou494
    const reCaptchaKey = hasToValidateCaptcha ? "6Lcom1gpAAAAAEWARc1vjBY8b22oFbrT5B1mGyB1" : null;
    
    return (
        hasToValidateCaptcha ?
            <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} language={Intl.Locale}>
                <GoogleReCaptcha onVerify={e => handleVerify(e)} />
                {children}
            </GoogleReCaptchaProvider>
        :
            <>{children}</>
    )
}
