import React from 'react'
import "../defaultV2.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { IntlContext } from './IntlWrapperProvider';
import { Converter } from "showdown";

export default function SurveyComponent({ FormData, css, onSubmit, DefaultData, hasToValidateCaptcha, token }) {
    const { locale } = React.useContext(IntlContext);
    const survey = new Model(FormData);
    
    survey.focusFirstQuestionAutomatic = false;

    survey.onCompleting.add((surveyData, options) => {
        if(hasToValidateCaptcha && !token){
            options.allowComplete = false;
            return;
        }
        else{
            onSubmit(surveyData, options);
        }
    });

    survey.locale = locale.split(/[-_]/)[0] !== "en" ? locale.split(/[-_]/)[0] : "en-gb";

    const converter = new Converter();
    survey.onTextMarkdown.add(function (survey, options) {
        if(!options || !options.text) return;
        let str = converter.makeHtml(options.text);
        if(str){
            str = str.substring(3);
            str = str.substring(0, str.length - 4);
        }
        options.html = str; 
    });
    
    if(DefaultData) survey.data = DefaultData;
    return (
        <div id='formComponent'>
            <style>
                {css}
            </style>
            <link rel="stylesheet" href="../defaultV2.css"/>
            
            <Survey model={survey} />
        </div>
    );
}