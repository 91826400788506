import axios from 'axios';

export function getAPI(callback, url) {
    axios.get(url)
        .then(res => callback({ data: res.data, isLoading: false }))
        .catch(err => callback({ error: err, isLoading: false }));
}

export function postAPI(callback, url, body) {
    axios.post(url, body, { headers: { 'crossDomain': true, 'Content-Type': 'application/json' } })
        .then(res => callback({ data: res.data, isLoading: false }))
        .catch(err => callback({ error: err, isLoading: false }));
}

export function putAPI(callback, url, body) {
    axios.put(url, body, { headers: { 'Content-Type': 'application/json' } })
        .then(res => callback({ data: res.data, isLoading: false }))
        .catch(err => callback({ error: err, isLoading: false }));
}

export function patchAPI(callback, url, body) {
    axios.patch(url, body)
        .then(res => callback({ data: res.data, isLoading: false }))
        .catch(err => callback({ error: err, isLoading: false }));
}

export function postMultiPartAPI(callback, url, body) {
    axios.post(url, body, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(res => callback({ data: res.data, isLoading: false }))
        .catch(err => callback({ error: err, isLoading: false }));
}

export function deleteAPI(callback, url, body) {
    axios.delete(url)
        .then(res => callback({ data: res.data, isLoading: false }))
        .catch(err => callback({ error: err, isLoading: false }));
}
