import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProviderWrapper } from './components/IntlWrapperProvider';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

root.render(
  <IntlProviderWrapper>
    <BrowserRouter basename={baseUrl}>
      <App tab="home" />
    </BrowserRouter>
  </IntlProviderWrapper>
);