import React from 'react'
import Block from '@availity/block-ui';

export default function BlockUi({ block, children }) {

    return (
        <>
            {block ?
                <div style={{ position: 'absolute', zIndex: '9999', minHeight: "100vh", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', background: '#ffffffa3' }}>
                    <Block
                        blocking={true}
                        tag='div'
                    />
                </div>
            :''}
                
            {children}
        </>
  )
}
