import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import '../App.css';
import { getAPI, postAPI } from './API';
import Header from './Header';
import SurveyComponent from './SurveyComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import ReCaptchaContainer from './ReCaptchaContainer';
import BlockUi from './BlockUi';

function Home() {

  const [block, setblock] = useState(true);
  const [error, seterror] = useState();
  const [userId, setuserId] = useState();
  const [formData, setformData] = useState();
  const [formId, setformId] = useState();
  const [defaultData, setdefaultData] = useState();
  const [availableLocales, setavailableLocales] = useState([]);
  const [hotelLogo, sethotelLogo] = useState();
  const [hotelName, sethotelName] = useState();
  const [renderModal, setrenderModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [isUnsubscribe, setisUnsubscribe] = useState();
  const [isConfirmEmail, setisConfirmEmail] = useState(false);
  const [hash, sethash] = useState();
  const [css, setcss] = useState();
  const [anonymous, setanonymous] = useState();
  const [success, setsuccess] = useState();
  const [token, setToken] = useState();
  const intl = useIntl()

  useEffect(() => {
    //Set Page Name
    document.title = "Form";

    const currentPath = document.location.pathname;
    const pathList = currentPath.split("/");
    const formId = pathList[1];
    const sharedKey = pathList[2];
    const txt = pathList[3];

    if(formId === "unsubscribe" && sharedKey && txt){
      unsubscribeEmail(sharedKey, txt);
    }
    if(formId === "ConfirmEmail" && sharedKey && txt){
      confirmEmail(sharedKey, txt);
    }
    else if(formId && sharedKey && txt){
      decryptData(sharedKey, txt, formId);
    }
    else if(formId){
      getForm(formId);
    }
  }, [])

  const confirmEmail = (sharedKey, data) => {
    postAPI(result => {
      const { data, error } = result;
      
      if(error || (data.errors && data.errors.length > 0)){
        setblock(false);
        seterror(true);
        setrenderModal(true);
        seterrorMessage(intl.formatMessage({ id: "Home.ConfirmEmailError" }));
        return;
      };
      setblock(false);
      setisConfirmEmail(true);

      return;
    }, `/api/gms/FormExternal/v1/confirmEmail`, { sharedKey, data: decodeURIComponent(data) });
  }

  const unsubscribeEmail = (sharedKey, data) => {
    postAPI(result => {
      const { data, error } = result;

      if(error || (data.errors && data.errors.length > 0) || !data){
        setblock(false);
        seterror(true);
        setrenderModal(true);
        seterrorMessage(intl.formatMessage({ id: "Home.UnsubscribeError" }));
        return;
      };
      setblock(false);
      setisUnsubscribe(true);
      return;
    }, `/api/gms/FormExternal/v1/unsubscribe`, { sharedKey, data: decodeURIComponent(data) });
  }

  const getAvailableLocales = (formData) => {
    const formLocales = typeof formData.title !== 'string' ? Object.keys(formData.title ?? "") : ['default']
    if(formLocales && formLocales.length > 0){
      if(formLocales.includes('default') && !availableLocales.includes('en')){
        formLocales.splice(formLocales.indexOf('default'), 1, 'en');
      } 

      if(formLocales.includes('en-gb') && !availableLocales.includes('en')){
        formLocales.splice(formLocales.indexOf('en-gb'), 1, 'en');
      }else if(formLocales.includes('en-gb') && availableLocales.includes('en')){
        formLocales.filter((locale) => locale !== 'en-gb');
        }
    }

    setavailableLocales(formLocales);
  }

  const decryptData = (sharedKey, txt, formId) => {
    getAPI(result => {
      const { data, error } = result;

      if(error || (data.errors && data.errors.length > 0) || (!data || data === "")){
        setblock(false);
        seterror(true);
        seterrorMessage(intl.formatMessage({ id: "Home.DecryptError" }));
        return;
      };
      if (data?.response && data.response?.length > 0) {
        const defaultData = parseQuery(data.response[0]);
        const userId = defaultData.userId;
        const hash = defaultData.hash;
        delete defaultData.userId;
        delete defaultData.hash;

        setformId(formId);
        setuserId(userId);
        sethash(hash);
        setdefaultData(defaultData);
        getForm(formId);
        
        return;
      };
      setblock(false);
      seterror(true);
      return;
    }, `/api/gms/FormExternal/v1/decrypt?sharedKey=${sharedKey}&data=${txt}`);
  };

  const parseQuery = (search) => {
    var args = search.split('&');
    var argsParsed = {};
    var i, arg, kvp, key, value;

    for (i=0; i < args.length; i++) {
        arg = args[i];
        if (-1 === arg.indexOf('=')) {
            argsParsed[decodeURIComponent(arg).trim()] = true;
        }
        else {
            kvp = arg.split('=');
            key = decodeURIComponent(kvp[0]).trim();
            value = decodeURIComponent(kvp[1]).trim();
            argsParsed[key] = value;
        };
    };
    return argsParsed;
  };

  const getForm = (formId) => {
    getAPI(result => {
      const { data, error } = result;

      if(error || (data.errors && data.errors.length > 0) || (!data || data === "") || !data.response){
        setblock(false);
        seterror(true);
        seterrorMessage(intl.formatMessage({ id: "Home.FormError" }));
        return;
      }
      if (data && data.response?.length > 0) {
        let json = JSON.parse(data.response[0].rawForm);
        const anonymous = data.response[0].anonymous;

        if(json){
          //Set Page Name
          if(data.response[0].title)
            document.title = data.response[0].title;
          
          setblock(false);
          sethotelLogo(data.response[0].imageUrl);
          setcss(data.response[0].css);
          setanonymous(anonymous);
          sethotelName(data.response[0].title);
          setformData(json);
          getAvailableLocales(json);
          setformId(formId);
          return;
        }
      };
    }, `/api/gms/FormExternal/v1/form/${formId}`);
  };

  const onSubmit = (survey, surveyOptions) => {
    if ((!userId || userId === "") && !anonymous) {
      surveyOptions.allowComplete = false;
      return;
    }
    setblock(true);
    setdefaultData(survey.data);

    const body = JSON.stringify(JSON.stringify(survey.data));

    postAPI(result => {
      const { data, error } = result;
      
      if(error || (data.errors && data.errors.length > 0)){
        var errorMessage = "Home.SubmitError";

        if(data && data.errors && data.errors.length > 0){
          if(data.errors && data.errors[0].code === 'GMSSUR0002') errorMessage = "Home.WrongFormError";
          if(data.errors && data.errors[0].code === 'GMSSUR0003') errorMessage = "Home.AlreadyAnsweredError";
        }

        surveyOptions.allowComplete = false;
        setblock(false);
        seterror(true);
        setrenderModal(true);
        seterrorMessage(intl.formatMessage({ id: errorMessage }));
        
        return;
      };
      if (error) {
        surveyOptions.allowComplete = false;
        setblock(false);
        setrenderModal(true);
        
        return;
      };
      if(data.errors && data.errors.length > 0){
        console.error(data.errors[0]?.message);
        surveyOptions.allowComplete = false;
        setblock(false);
        setrenderModal(true);
        seterrorMessage(data.errors[0]?.message);
        return;
      };
      setblock(false);
      setrenderModal(true);
      setsuccess(true);
      seterrorMessage(getSucessMessage());

      return;
    }, `/api/gms/FormExternal/v1/form/${formId}${userId ? '?userId=' + userId : ''}${hash ? `&hash=${hash}` : ''}`, body);
  };

  const getSucessMessage = () => {
    const locale = intl.locale;

    if(formData.completeText && formData.completeText !== "Submit" && (!formData.completeText.default || formData.completeText.default !== "Submit")) {
      if(typeof formData.completeText === 'object'){
        const surveyLocale = locale.split(/[-_]/)[0] !== "en" ? locale.split(/[-_]/)[0] : "en-gb";
        const locSucess = formData.completeText[surveyLocale];

        if(locSucess)
          return locSucess;
        
        return formData.completeText.default;
      }
      else{
        return formData.completeText;
      }
    }

    return intl.formatMessage({ id: "Home.Thanks" });
  }

  const handleVerify = (e) => {
    if(!token)
      setToken(e)
  }

  const hasToValidateCaptcha = !renderModal && formData && !error && !isUnsubscribe && anonymous;

  return (
      <ReCaptchaContainer handleVerify={handleVerify} hasToValidateCaptcha={hasToValidateCaptcha}>
        <BlockUi block={block}>
          <div className="App" style={isUnsubscribe ? { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', overflow: 'hidden' } : {}}>
            {css ?
              <style>
                {css}
              </style>
            :''}
            {isUnsubscribe ? 
              <div style={{ textAlign: 'center', background: 'white', padding: '2rem' }}>
                <div>
                  <h4><FormattedMessage id="Home.UnsubscribeTitle"/></h4>
                </div>
                <div className='mt-2'>
                  <FormattedMessage id="Home.UnsubscribePart"/>
                </div>
              </div>
            :''}
            {renderModal ?
              <Modal isOpen className='modal-lg p-3' style={{ marginTop: '35vh' }}>
                <ModalBody>
                  <Row className='text-center mb-2 clas mt-2'>
                    <Col>
                      {success ?
                        <>
                          <div>
                            <h5><FormattedMessage id="Home.Success"/></h5>
                          </div>
                          <div>
                            {errorMessage ? 
                              errorMessage
                            :
                              <FormattedMessage id="Home.Thanks"/>
                            }
                          </div>
                        </>
                      :
                      errorMessage ? 
                        <h5>{errorMessage}</h5>
                      :
                        <span><FormattedMessage id="Home.defaultErrorMessage"/></span>
                      }
                    </Col>
                  </Row>
                  <Row className='my-2'>
                    <Col className="text-center">
                      <Button onClick={() => window.location.reload()}>
                        Ok
                      </Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            :''}
            {error ?
              <div style={{ height: '50vh', width:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h3><FormattedMessage id="Home.UnavailableForm"/></h3>
              </div>
            : formData ?
              <>
                {hotelLogo || hotelName ?
                  <Header
                    availableLocales={availableLocales}
                    hotelLogo={hotelLogo}
                    hotelName={hotelName}
                  />
                :''}
                <SurveyComponent
                  FormData={formData}
                  intl={intl}
                  token={token}
                  hasToValidateCaptcha={hasToValidateCaptcha}
                  theme={"defaultV2"}
                  onSubmit={onSubmit}
                  DefaultData={defaultData}
                />
              </>
            : isConfirmEmail ?
                <div className='p-4'>
                  <div className='d-flex align-items-start justify-content-center'>
                    <h4><FormattedMessage id="Home.ConfirmEmailTitle"/></h4>
                    <i style={{ color: '#0B9E2E', fontSize: '2em' }} className="fas fa-check-circle ml-3"/>
                  </div>
                  <div className='mt-2'>
                    <FormattedMessage id="Home.ConfirmEmailText"/>
                  </div>
                </div>
            :''}
          </div>
        </BlockUi>
      </ReCaptchaContainer>
  );
};

export default Home;